<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<notificacion-form-header :modelPk="modelPk"></notificacion-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<pui-master-detail
				componentName="resolucion-grid"
				:parentModelName="modelName"
				:parentPk="pk"
				:parentPkChildFk="{ idnotif: 'idnotif' }"
				:modalDialog="isModalDialog"
				:formDisabled="true"
			></pui-master-detail>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'notificacionresolucion-form',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'notificacion'
		};
	},
	methods: {
		afterGetData() {
			//console.log(this.modelPk);
			//console.log(this.pk);
		}
	},
	computed: {
		getTitleDatos() {
			return this.$t('form.notificacion');
		}
	},
	created() {}
};
</script>
